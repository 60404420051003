import noticeManager from '@/util/notice-manager';
import homeService from '../service/home-service';
import JoinUsSaveDto from '../dto/JoinUsSaveDto';
import LandingFeedbackSaveDto from '../dto/LandingFeedbackSaveDto';
export default {
  name: 'HomeApp',
  data() {
    return {
      joinUsSaveDto: new JoinUsSaveDto(),
      landingFeedbackSaveDto: new LandingFeedbackSaveDto()
    };
  },
  methods: {
    handleSubmitClick() {
      this.saveLandingJoinUs();
    },
    validEmail() {
      if (this.joinUsSaveDto.email === '') {
        noticeManager.showToast('please enter your email', 'warning');
        return new Promise(resolve => {
          resolve(false);
        });
      }
      if (!this.joinUsSaveDto.email.match(/^(\w-*\.*)+@(\w-?)+(\.\w{2,})+$/)) {
        noticeManager.showToast('please enter valid email', 'warning');
        return new Promise(resolve => {
          resolve(false);
        });
      }
      return true;
    },
    async saveLandingJoinUs() {
      const isVaild = await this.validEmail();
      if (isVaild) {
        const result = await homeService.saveLandingJoinUs(this.joinUsSaveDto);
        if (result.isSuccess()) {
          this.joinUsSaveDto.companyName = '';
          this.joinUsSaveDto.email = '';
          noticeManager.showToast('submit successfully');
        } else {
          noticeManager.showToast(result.msg);
        }
      }
    },
    handleCommentSubmitClick() {
      this.saveLandingFeedback();
    },
    validFeedback() {
      if (this.landingFeedbackSaveDto.comments === '') {
        noticeManager.showToast('please enter your comments', 'warning');
        return new Promise(resolve => {
          resolve(false);
        });
      }
      this.landingFeedbackSaveDto.email = this.joinUsSaveDto.email;
      return true;
    },
    async saveLandingFeedback() {
      const isVaild = await this.validFeedback();
      if (isVaild) {
        const result = await homeService.saveLandingFeedback(this.landingFeedbackSaveDto);
        if (result.isSuccess()) {
          this.landingFeedbackSaveDto.comments = '';
          noticeManager.showToast('submit successfully');
        } else {
          noticeManager.showToast(result.msg);
        }
      }
    }
  }
};