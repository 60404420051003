import utilManager from '@/util/util-manager'

class JoinUsSaveDto {
  country
  companyName
  email
  operationSystem
  browser

  constructor () {
    this.country = utilManager.getCountry();
    this.companyName = '';
    this.email = '';
    this.operationSystem = utilManager.getOsType();
    this.browser = utilManager.getBrowserType();
  }



}

export default JoinUsSaveDto
