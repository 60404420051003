const noticeManager = {

  showToast (text) {
    if (document.getElementById('optimus-toast')) {
      const toast = document.getElementById('optimus-toast')
      toast.innerHTML = text
      toast.style.display = 'block'
      this.hiddenToast()
    } else {
      const toast = document.createElement('div')
      toast.className = 'toast'
      toast.id = 'optimus-toast'
      toast.innerHTML = text
      document.body.appendChild(toast)
      this.hiddenToast()
    }
  },

  hiddenToast () {
    setTimeout(function () {
      const toast = document.getElementById('optimus-toast')
      toast.style.display = 'none'
    }, 2000)
  },

  showMessage(message,type){
      const messageBox = document.createElement('div')
      messageBox.classList.add('optimus-message',`optimus-message-${type}`)
      messageBox.id = 'optimus-message'
      messageBox.innerHTML = message
      document.body.appendChild(messageBox)
      this.hiddenMessage()
  },

  hiddenMessage(){
    setTimeout(function () {
      const message = document.getElementById('optimus-message')
      message.remove();
    }, 3000)
  }

}

export default noticeManager
