import utilManager from '@/util/util-manager'

class LandingFeedbackSaveDto {
  country
  comments
  email
  operationSystem
  browser

  constructor () {
    this.country = utilManager.getCountry();
    this.comments = ''
    this.email = ''
    this.operationSystem = utilManager.getOsType()
    this.browser = utilManager.getBrowserType()
  }

}

export default LandingFeedbackSaveDto
