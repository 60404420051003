import Home from './components/Home.vue';
import HomeApp from './components/HomeApp.vue';
export default {
  name: 'App',
  components: {
    Home,
    HomeApp
  },
  data() {
    return {
      isMobile: true
    };
  },
  created() {
    this.handleResize();
    this.handlePageNavigate();
  },
  mounted() {
    window.addEventListener('resize', this.handleResize);
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.handleResize);
  },
  methods: {
    handlePageNavigate() {
      const url = window.location.href;
      if (url.indexOf('navigate') !== -1) {
        const userAgent = navigator.userAgent;
        const isiOS = !!userAgent.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
        if (isiOS) {
          window.location.href = 'https://apps.apple.com/app/optimus-fta/id6447542304';
        } else {
          window.location.href = 'https://play.google.com/store/apps/details?id=com.optimus.fta';
        }
      }
    },
    handleIsMobile() {
      return document.body.getBoundingClientRect().width < 769;
    },
    handleResize() {
      if (!document.hidden) {
        this.isMobile = this.handleIsMobile();
      }
    }
  }
};