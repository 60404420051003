const utilManager = {

  getCountry(){
    const language = window.navigator.language;
    return language
  },

  getOsType(){
    const ua = window.navigator.userAgent;
    const isAndroid = ua.indexOf('Android') > -1 || ua.indexOf('Adr') > -1; //android终端
    if (isAndroid) {
      return 'ANDROID';
    }
    const isiOS = !!ua.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
    if (isiOS) {
      return 'IOS';
    }
    const isPc = ua.indexOf('Windows') > -1;
    if (isPc) {
      return 'Windows';
    }
    const isMac = ua.indexOf('Mac') > -1;
    if (isMac) {
      return 'Mac'
    }
  },

  getBrowserType () {
    let ua = navigator.userAgent.toLocaleLowerCase()
    let browserType = null
    if (ua.match(/msie/) != null || ua.match(/trident/) != null) {
      browserType = 'IE'
    } else if (ua.match(/firefox/) != null) {
      browserType = 'firefox'
    } else if (ua.match(/ucbrowser/) != null) {
      browserType = 'UC'
    } else if (ua.match(/opera/) != null || ua.match(/opr/) != null) {
      browserType = 'opera'
    } else if (ua.match(/bidubrowser/) != null) {
      browserType = 'baidu'
    } else if (ua.match(/metasr/) != null) {
      browserType = 'sougou'
    } else if (ua.match(/tencenttraveler/) != null || ua.match(/qqbrowse/) != null) {
      browserType = 'QQ'
    } else if (ua.match(/maxthon/) != null) {
      browserType = 'maxthon'
    } else if (ua.match(/chrome/) != null) {
      browserType = 'chrome'
    } else if (ua.match(/safari/) != null) {
      browserType = 'Safari'
    } else {
      browserType = 'others'
    }
    return browserType
  }

}

export default utilManager
