import axios from "axios";
import Result from "@/util/Result";

const homeService = {

  async saveLandingJoinUs(landingJoinUsSaveDto) {
    try {
      const response = await axios.post(`http://app.optimusfta.com/api/landing-join-us`, landingJoinUsSaveDto)
      return Result.success().data(response.data).build()
    } catch (error) {
      return Result.error(`sorry, something wrong happened, please try again or wait for a moment. `).build()
    }
  },

  async saveLandingFeedback(landingFeedbackSaveDto) {
    try {
      const response = await axios.post(`http://app.optimusfta.com/api/landing-feedback`, landingFeedbackSaveDto)
      return Result.success().data(response.data).build()
    } catch (error) {
      return Result.error(`sorry, something wrong happened, please try again or wait for a moment. `).build()
    }
  }

}

export default homeService
